import React from 'react';
import { graphql, Link } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import LazyLoad from 'react-lazyload';
import Textbox from '../components/text/Textbox';

const Event = styled.div`
  margin-bottom: 144px;

  & a {
    text-decoration: none;
    color: red;
  }
`;

const EventTitle = styled.div`
  text-align: center;

  color: #000;

  padding: 30px 0 0 0;

  & h2 {
    @media (max-width: 767px) {
      font-size: 27px;
    }

    @media (max-width: 420px) {
      font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
    }
  }
`;

const Projects = ({ data }) => {
  const content = data.prismicCurrent.data.posts
    .filter(
      (content) =>
        content.post.document &&
        content.post.document.data.display_in_current === 'True'
    )
    .map((content, index) => {
      if (
        content.post.document.type === 'news' ||
        content.post.document.type === 'event'
      ) {
        const { featured } = content.post.document.data;
        let dimensions =
          content.post.document.data.image.dimensions !== null
            ? content.post.document.data.image.dimensions
            : null;
        let imageWidth = dimensions !== null ? dimensions.width : null;
        let imageHeight = dimensions !== null ? dimensions.height : null;
        const colSpan = featured === 'True' ? 12 : 6; // Changed to 6 for non-featured items

        return (
          <Col col={12} key={index}>
            <LazyLoad height={400}>
              <Row justifyContent="center">
                <Col col={12} md={colSpan}>
                  <Event>
                    {content.post.document.type === 'event' ? (
                      <Link to={'/current/' + content.post.document.uid}>
                        <div>
                          {imageWidth > imageHeight ? (
                            <img
                              src={content.post.document.data.image.url}
                              loading={index <= 2 ? 'auto' : 'lazy'}
                              alt={content.post.document.data.image.alt}
                            />
                          ) : (
                            <Row justifyContent="center">
                              <Col col={9} md={8}>
                                <img
                                  src={content.post.document.data.image.url}
                                  loading={index <= 2 ? 'auto' : 'lazy'}
                                  alt={content.post.document.data.image.alt}
                                />
                              </Col>
                            </Row>
                          )}

                          <EventTitle>
                            <h2>{content.post.document.data.title.text}</h2>
                          </EventTitle>
                        </div>
                      </Link>
                    ) : (
                      <div>
                        {imageWidth > imageHeight ? (
                          <img
                            src={content.post.document.data.image.url}
                            loading={index <= 2 ? 'auto' : 'lazy'}
                            alt={content.post.document.data.image.alt}
                          />
                        ) : (
                          <Row justifyContent="center">
                            <Col col={9} md={8}>
                              <img
                                src={content.post.document.data.image.url}
                                loading={index <= 2 ? 'auto' : 'lazy'}
                                alt={content.post.document.data.image.alt}
                              />
                            </Col>
                          </Row>
                        )}

                        <EventTitle>
                          {featured}
                          <h2>{content.post.document.data.title.text}</h2>
                          <Textbox
                            margin={`20px auto`}
                            mobileMargin={`26px auto 20px auto`}
                            textAlign={`left`}
                            text={content.post.document.data.text.html}
                          />
                        </EventTitle>
                      </div>
                    )}
                  </Event>
                </Col>
              </Row>
            </LazyLoad>
          </Col>
        );
      }
      return null;
    });

  return (
    <>
      <Helmet title={`Current - Askeaton Contemporary Arts`} />
      {content}
    </>
  );
};

export default Projects;

export const query = graphql`
  {
    prismicCurrent {
      data {
        posts {
          post {
            document {
              ... on PrismicEvent {
                id
                uid
                data {
                  image {
                    url
                    alt
                    dimensions {
                      width
                      height
                    }
                  }
                  title {
                    text
                  }
                  display_in_current
                  featured
                }
                type
              }
              ... on PrismicNews {
                id
                data {
                  title {
                    text
                  }
                  text {
                    html
                  }
                  image {
                    url
                    alt
                    dimensions {
                      width
                      height
                    }
                  }
                  date
                  location
                  display_in_current
                  featured
                }
                type
              }
            }
          }
        }
      }
    }
  }
`;
